import React from "reactn";
import {
  LiveView1,
  LiveView2,
  LiveView3,
  LiveView5,
  OrangePin
} from "../../assets";
import GoogleMapReact from "google-map-react";
import Checkout from "./checkout";
import {
  addMonths,
  addDays,
  format,
  addWeeks,
  subDays /*isBefore*/
} from "date-fns";
import { calculateProratedClasses } from "./Checkout/utils";
import axios from "axios";
import SignIn from "../../components/modals/SignInModal";
import SignUpNewModal from "../../components/modals/SignUpNewModal";
import CartModal from "../../components/parents/CartModal";
import { withStyles } from "@material-ui/core/styles";
import "../../assets/css/componentSpecificCss/liveSpecificPages.css";
import { toast } from "react-toastify";
//import { DateTime } from "luxon";
import {
  constructTimeRangeFromDecimalHours,
  getFormattedTimeRange,
  getGroupedWeekdayRange,
  testIsInteger
} from "./SpecificPageUtils/utils";
import { ReactBnbGallery } from "react-bnb-gallery";
import Radio from "@material-ui/core/Radio";
import NoDataAvailable from "../../components/front/partner/NoDataAvailable";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
//import { green } from "@material-ui/core/colors";
import SummarySubTotal from "./SpecificPageUtils/SummarySubTotal";
import { withRouter } from "react-router-dom";
import qs from "qs";
import moment from "moment-timezone";
import { DateTime } from "luxon";
import ShareButtonModal from "../../components/modals/ShareButtonModal";
import { sendAddToCartEvent } from "../../components/integrations/marketingIntegrationsUtils";

const styles = theme => ({
  plan: {
    transition: "all 300ms",
    boxShadow: "0 2px 5px 0 rgba(0,0,0,.2)",
    "&:hover": {
      transform: "scale(1.02) translateY(-3px)",
      boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
    }
  },
  planSelected: {
    transform: "scale(1.02) translateY(-3px)",
    boxShadow: "0 7px 12px 0 rgba(0,0,0,.3)"
  },
  cardImg: {
    width: "100% !important",
    height: "230px !important",
    boxShadow: "0px -1px 10px rgba(0, 0, 0, 0.15) !important",
    borderRadius: "7px !important",
    objectFit: "cover",
    maxHeight: "300px",
    [theme.breakpoints.down(1164)]: {
      height: "150px !important"
    }
  },
  radio: {
    color: "#ccc",
    "&$checked": {
      color: "#FF6666"
    },
    "& .MuiButtonBase-root": {
      padding: "0px !important",
      marginRight: "0px !important"
    }
  },
  checkedRadio: {
    color: "#FF6666"
  }
});

const daysOfWeekConversion = day => {
  const table = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  };
  return table[day];
};

const getFirstDay = (startingDate, daysOfWeek) => {
  console.log("daysOfWeek...", daysOfWeek);
  if (Object.keys(daysOfWeek).length === 0) return;

  const convertedDaysOfWeek = Object.keys(daysOfWeek).map(e =>
    daysOfWeekConversion(e)
  );
  while (true) {
    if (convertedDaysOfWeek.includes(startingDate.getDay())) {
      return startingDate;
    }
    startingDate = addDays(startingDate, 1);
  }
};

/**
 * Returns lastDay + 1week - 1day, and then iterate backwards through the days until it hits one of the daysOfWeek.
 * @param {Date} lastDay
 * @param {*} daysOfWeek
 * @returns {Date}
 */
const getLastDay = (lastDay, daysOfWeek) => {
  console.log("lastOfWeek...", daysOfWeek);
  if (Object.keys(daysOfWeek).length === 0) return;
  const convertedDaysOfWeek = Object.keys(daysOfWeek).map(e =>
    daysOfWeekConversion(e)
  );
  lastDay = addWeeks(lastDay, 1);
  lastDay = subDays(lastDay, 1);
  while (true) {
    if (convertedDaysOfWeek.includes(lastDay.getDay())) {
      return lastDay;
    }
    lastDay = subDays(lastDay, 1);
  }
};

class SpecificProgram extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      textShown: false,
      mapShown: false,
      gallery: [],
      program: {
        age_range: [],
        timing: [],
        plans: [],
        age_groupings: [],
        days_of_week: {},
        other_locations: []
      },
      selected: null,
      selectedPlan: null,
      checkout: false,
      isOpenSignUpModal: false,
      showDescription: true,
      showAdditionalDetails: true,
      showLocation: true,
      isEnableWaitlist: false,
      isProgramWaitlist: false,
      isBookingFull: false,
      showCancellationPolicy: true,
      singleSessionQuantity: [],
      startDate: null,
      recurringCustomerSelectedDays: [],
      isSkipForm: false,
      isWaiveDeposit: false,
      isStartSet: false,
      orgSlug: "",
      customers: {
        Enrollments: []
      },
      planWaitlist: false,
      singleSessionSelectedDates: [],
      email: "",
      checkoutType: "Regular",
      showCart: false,
      disableAddToCart: false,
      isInterested: false,
      dsUserId: -1
    };

    this.updateScreenSize = this.updateScreenSize.bind(this);
  }

  async componentDidUpdate(prevProps, prevState, ss) {
    if (prevState.selected !== this.state.selected) {
      const selectedPlanJSON = this.state.program?.plans?.find(
        e => JSON.parse(e).id === this.state.selected
      );
      console.assert(
        !selectedPlanJSON,
        `No plan found with id=${this.state.selected}`
      );
      if (!selectedPlanJSON) return;

      const selectedPlan = JSON.parse(selectedPlanJSON);
      console.log("selectedPlan...", selectedPlan);

      this.setState({
        selectedPlan
      });
    }

    const freeTrialPlans = this.state.program.plans.filter(plan => {
      let parsedPlan = plan;
      if (typeof plan === 'string') {
        parsedPlan = JSON.parse(plan);
      }
      return parsedPlan.type === 'Free Trial';
    });

    if (this.global.dsUser && freeTrialPlans.length > 0 && this.state.dsUserId !== this.global.dsUser.id) {
      const queryParams = this.props.location.search
      ? this.props.location.query ||
      this.props.history.location.query ||
      qs.parse(this.props.location.search.substr(1))
      : {};
      console.log("ENTERED HERE!");
      this.setState({
        dsUserId: this.global.dsUser.id
      });

      // check if the dsUser is a first-time customer
      let newPlans = this.state.program.plans.filter(plan => {
        let parsedPlan = plan;
        if (typeof plan === 'string') {
          parsedPlan = JSON.parse(plan);
        }
        return parsedPlan.type !== 'Free Trial';
      });

      for (let plan of freeTrialPlans) {
        plan = typeof plan === "string" ? JSON.parse(plan) : plan;
        console.log("PLAN: ", plan);
        const ep3 = `${process.env.REACT_APP_API}/par/account/${plan.separateForChildren ? "child/" : ""}is-first-time`;
        if (plan.separateForChildren) {
          const ep4 = `${process.env.REACT_APP_API}/par/account/get-children`;
          const results4 = await axios.get(ep4, { params: { parentId: this.global.dsUser.id }});

          const children = results4.data.data;
          console.log("Children info: ", children);
          let availableChildren = [];
          for (let child of children) {
            const results3 = await axios.get(ep3, {
              params: {
                childId: child.id,
                demographic: plan.trialDemographic,
                listingType: "Programs",
                listingId: this.state.program.id,
                partnerId: this.state.program.partnerId
              }
            });

            if (results3.data.data) {
              console.log("RESULTS: ", results3.data.data);
              availableChildren.push(child);
            }
          }
          console.log("LENGTH: ", availableChildren.length);
          if (availableChildren.length === children.length) {
            newPlans.push(JSON.stringify({
              ...plan,
              noChildrenAvailable: false,
              allChildrenAvailable: true,
              availableChildren: availableChildren
            }));
          } else if (availableChildren.length > 0) {
            newPlans.push(JSON.stringify({
              ...plan,
              noChildrenAvailable: false,
              allChildrenAvailable: false,
              availableChildren: availableChildren
            }));
          } else if (parseInt(queryParams.plan) === plan.id) {
            newPlans.push(JSON.stringify({
              ...plan,
              noChildrenAvailable: false,
              allChildrenAvailable: true,
              availableChildren: children
            }));
          }
        } else {
          const results3 = await axios.get(ep3, {
            params: {
              userId: this.global.dsUser.id,
              demographic: plan.trialDemographic,
              listingType: "Programs",
              listingId: this.state.program.id,
              partnerId: this.state.program.partnerId
            }
          });

          const isFirstTimeCustomer = results3.data.data;
  
          if (isFirstTimeCustomer) {
            newPlans.push(JSON.stringify(plan));
          }
        }
      }

      this.setState({
        program: {
          ...this.state.program,
          plans: newPlans
        }
      }, () => {
        if (prevState.justSignedIn !== this.state.justSignedIn) {
          if (this.state.checkoutType === "Cart" && this.state.program.plans.filter(plan => JSON.parse(plan).id === this.state.selected).length !== 0) {
            this.onAddToCart();
          } else if (this.state.checkoutType === "Save") {
            this.saveInterestedProgram();
          } else if (this.state.program.plans.filter(plan => JSON.parse(plan).id === this.state.selected).length !== 0){
            this.setState({ checkout: true });
          } else {
            this.setState({ selected: null });
          }
        }
      });
    } else if (freeTrialPlans.length === 0 && prevState.justSignedIn !== this.state.justSignedIn) {
      if (this.state.checkoutType === "Cart") {
        this.onAddToCart();
      } else if (this.state.checkoutType === "Save") {
        this.saveInterestedProgram();
      } else {
        this.setState({ checkout: true });
      }
    }
  }

  async componentDidMount() {
    console.assert(
      testIsInteger(this.props.match.params.id),
      "Invalid program Id specified: " + this.props.match.params.id
    );

    this.setGlobal({ loading: true });
    this.updateScreenSize();
    window.addEventListener("resize", this.updateScreenSize);
    const ep = `${process.env.REACT_APP_API}/partners/search/program/${this.props.match.params.id}`;
    let results;
    try {
      results = await axios.get(ep);
    } catch (err) {
      console.error(err.message);
      console.log("Error when making request to: ", ep);
    }

    console.log("DSUser: ", this.global.dsUser);
    console.log("listing: ", results.data.data);

    if (this.global.dsUser) {
      await this.dispatch.getCart({partnerSlug: this.props.match.params.slug});
      const ep2 = `${process.env.REACT_APP_API}/par/enrollment/get-interested`;
      const results2 = await axios.get(ep2);

      const myInterestedArr = results2?.data?.data?.filter(program => {
        return program.programId == this.props.match.params.id;
      });

      const isSavedAsInterested =
        myInterestedArr.length > 0 && !myInterestedArr[0].archived
          ? true
          : false;

      this.setState({
        isInterested: isSavedAsInterested
      });
      /*
      // check if the dsUser is a first-time customer
      const ep3 = `${process.env.REACT_APP_API}/par/account/is-first-time`;
      const results3 = await axios.get(ep3, {
        params: {
          userId: this.global.dsUser.id,
          demographics: results.data.data.plans
            .map(plan => typeof plan === 'string' ? JSON.parse(plan) : plan)
            .filter(plan => plan.type === "Free Trial")[0].trialDemographic,
          listingType: "Programs",
          listingId: results.data.data.id,
          partnerId: results.data.data.partnerId
        }
      });

      isFirstTimeCustomer = results3.data.data;
      */
    }
    
    const data = results.data.data;

    /*
    if (!isFirstTimeCustomer) {
      data.plans = data.plans.filter(plan => {
        let parsedPlan = plan;
        if (typeof plan === "string") {
          parsedPlan = JSON.parse(plan);
        }
        return parsedPlan.type !== "Free Trial";
      });
    }
  */

    if (!results.data.success || data.isArchived) {
      this.props.history.push("/");
      this.setGlobal({ loading: false });
      return;
    }

    this.setState({
      orgSlug: data.slug,
      timezone: "Canada/Eastern"
    });

    let gallery;
    if (this.props.match.params.slug) {
      const gallery_ep = `${process.env.REACT_APP_API}/partners/search/profile/gallery/${this.props.match.params.slug}`;
      try {
        gallery = await axios.get(gallery_ep);
      } catch (err) {
        console.error(err.message);
        console.log("Error when making request to: ", gallery_ep);
      }
    }

    this.setState({
      customers: { Enrollments: data.enrollments }
    });

    console.log(
      "partnerSpecificProgram.jsx 179: this.props.location",
      this.props.location
    );

    //I'm not entirely sure which is better practice: using props.location or props.history.location.
    //So, I'm just going to access both and do some qs.parsing as a last resort.
    const queryParams = this.props.location.search
      ? this.props.location.query ||
      this.props.history.location.query ||
      qs.parse(this.props.location.search.substr(1))
      : {};

    if (queryParams && queryParams.plan) {
      this.setState({
        selected: parseInt(queryParams.plan),
        installments: queryParams.modifier === "Installments" || queryParams.modifier === "I",
        dropIns: queryParams.modifier === "Drop-In" || queryParams.modifier === "D",
        checkout: true,
        startDate: queryParams.startDate
          ? new Date(queryParams.startDate)
          : null,
        recurringCustomerSelectedDays: queryParams.recurringCustomerSelectedDays
          ? JSON.parse(queryParams.recurringCustomerSelectedDays)
          : null,
        isSkipForm: queryParams.skipForm === "true",
        isWaiveDeposit: queryParams.waiveDeposit === "true",
        isStartSet: queryParams.isStartSet === "true",
        singleSessionQuantity: [parseInt(queryParams.singleSessionQuantity)],
        singleSessionSelectedDates: queryParams.singleSessionSelectedDates
          ? JSON.parse(queryParams.singleSessionSelectedDates)
          : []
      });
    }

    if (queryParams && queryParams.visible && queryParams.visible === "true") {
      this.setGlobal({
        footer: false
      })
    }

    console.log(
      "parterSpecificProgram.jsx 213: this.props.location.query",
      queryParams
    );

    /*** COMMENTING OUT THIS SEEMINGLY REDUNDANT SECTION! DELETE IN A FEW MONTHS TIME!
 *** It's just mostly a repetition of the chunk a few lines above,
 *** but using "this.props.history.location" instead of "this.props.location"
 *** I tested it: this.props.history.location === this.props.location   //is true!

    // if (!this.props.location.query) {
    if (this.props.location.query) {
      console.log("line 161");
      this.setState({
        checkout: true,
        startDate: this.props.history.location.query.startDate
          ? new Date(this.props.history.location.query.startDate)
          : null,
        recurringCustomerSelectedDays: this.props.history.location.query
          .recurringCustomerSelectedDays
          ? JSON.parse(
              this.props.history.location.query.recurringCustomerSelectedDays
            )
          : null,
        isSkipForm: this.props.history.location.query.isSkipForm == "true",
        isWaiveDeposit:
          this.props.history.location.query.isWaiveDeposit == "true",
        isStartSet: this.props.history.location.query.isStartSet == "true"
      });
    }
***/

    //BELOW IS COMMENTED OUT! DELETE LATER! Unsure why this needs to be called twice in succession.
    //this.fetchEnrollmentCount(results.data.data.id);
    let isCountLargerThanSpots;
    try {
      isCountLargerThanSpots = !!(
        data.total_spots &&
        (await this.fetchEnrollmentCount(data.id)) >= data.total_spots
      );
    } catch(err) {
      console.error(err.message);
      console.log("Error when making calling the fetchEnrollmentCount function");
    }

    // let alreadyEnrolled = false;
    // if (this.global.dsUser) {
    //   const allEnrollments = await this.fetchUserEnrollments();
    //   alreadyEnrolled = allEnrollments.filter(user =>
    //     user.dsUserId === this.global.dsUser.id &&
    //     user.programId === data.id &&
    //     user.status_stage !== "Cancelled"
    //   ).length > 0;
    // }

    const isEnableWaitlist = !!(data.enable_waitlist && isCountLargerThanSpots);

    const isBookingFull =
      isCountLargerThanSpots &&
      !isEnableWaitlist &&
      data.program_details !== "Lessons / Sessions";

    this.setState({
      program: data,
      isProgramWaitlist: data.enable_waitlist,
      isEnableWaitlist: isEnableWaitlist,
      isBookingFull: isBookingFull,
      gallery: gallery?.data?.data ? gallery?.data?.data : []
    });

    if (this.props.location.query && this.props.location.query.plan) {
      this.setState({
        selected: parseInt(this.props.location.query.plan),
        isSkipForm: this.props.location.query.isSkipForm == "true"
      });
    }
    // if (
    //   this.props.location.search &&
    //   this.props.location.search.includes("visible=true")
    // ) {
    //   const planNumber = parseInt(
    //     this.props.location.search.split("&")[0].split("=")[1]
    //   );
    //   console.log(
    //     "plan number",
    //     this.props.location.search.split("&")[0].split("=")[1]
    //   );
    //   this.setState({ selected: planNumber });
    // }

    this.setGlobal({ loading: false });
  }

  fetchEnrollmentCount = async programId => {
    const ep = `${process.env.REACT_APP_API}/partners/programs/enrollments/count/${programId}`;
    const result = await axios.get(ep);

    this.setState({
      enrollmentCount: parseInt(result.data.data.count)
    });

    return parseInt(result.data.data.count);
  };

  fetchUserEnrollments = async () => {
    const ep = `${process.env.REACT_APP_API}/partners/programs/par/customers/all/${this.global.dsUser.id}`;
    const result = await axios.get(ep);

    return result.data.data;
  }

  getLocation(locations) {
    const data = locations.filter(
      e => e.address === this.state.program.address1
    );
    return data.length ? (
      <div
        lat={data[0].lat}
        lng={data[0].lng}
        style={{
          backgroundImage: `url(${OrangePin})`,
          backgroundSize: "cover",
          height: 30,
          width: 22,
          cursor: "pointer",
          zIndex: 10,
          position: "relative",
          bottom: 15,
          right: 15
        }}
      ></div>
    ) : (
      <div
        lat={this.state.program.main_lat}
        lng={this.state.program.main_long}
        style={{
          backgroundImage: `url(${OrangePin})`,
          backgroundSize: "cover",
          height: 30,
          width: 22,
          cursor: "pointer",
          zIndex: 10,
          position: "relative",
          bottom: 15,
          right: 15
        }}
      ></div>
    );
  }

  updateScreenSize() {
    this.setState({
      showDescription: window.innerWidth > 600,
      showAdditionalDetails: window.innerWidth > 600,
      showLocation: window.innerWidth > 600
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenSize);
  }

  openGallery = () => {
    this.setState({
      galleryShown: !this.state.galleryShown
    });
  };

  saveInterestedProgram = async () => {
    const ep = `${process.env.REACT_APP_API}/par/enrollment/save-interested-program`;
    const programCopy = this.state.program;
    delete programCopy.enrollments;
    const results = await axios.post(ep, {
      program: programCopy,
      type: "Program"
    });

    if (results.data.success) {
      if (results?.data?.data?.archived) {
        toast.success("Removed as interested program");
      } else {
        toast.success("Saved as interested program!");
      }
      this.setState({ isInterested: !this.state.isInterested });
    }
  };

   //=================================================================================================
   failedLocalStorageDsUserToken = () => {
    try {
      if (localStorage.getItem("ds_token")) {
        return false;
      }
      return false;
    } catch {
      console.log("FAILED TO FETCH FROM LOCAL STORAGE");
      return true;
    }
  };

  isPlanSelected = () =>
    this.state.selected !== null && this.state.selected !== undefined; //do not use -1 to represent a non-selection. And id=0 is a valid price plan!

  getAccountType = user => user.accountType;
  isUserParent = user => this.getAccountType(user) === "Parent";
  isUserVolunteer = user => this.getAccountType(user) === "Volunteer";
  isUserStudent = user => this.getAccountType(user) === "Student";
  canUserVisitCheckout = user =>
    this.isUserParent(user) || this.isUserVolunteer(user) || this.isUserStudent(user);
  checkUserAndLoggedInAccountType = () =>
    this.global.dsUser && this.canUserVisitCheckout(this.global.dsUser);

  //===========================================================================
  // click handler for Enroll Now button
  //===========================================================================
  onEnrollNow = async () => {
    if (!this.isPlanSelected()) {
      return toast.error(`Please select a plan!`);
    }
    const planId = this.state.selected;

    if (this.failedLocalStorageDsUserToken()) {
      console.log(
        "onEnrollNow: Failed to access local storage. Invoking window.open()."
      );
      window.open(
        `${process.env.REACT_APP_URL}/programs/${this.props.match.params.slug}/program/${this.state.program.id}?plan=${planId}`
      );
      return;
    }

    this.setState({ checkoutType: "Regular" });

    if (localStorage.getItem("ds_token") && !this.global.dsUser) {
      await this.dispatch.validateToken();
      if (this.checkUserAndLoggedInAccountType()) {
        return this.setState({ checkout: true });
      }
      this.dispatch.logout();
      return this.setState({ isOpenSignUpModal: true });
    }
    if (!localStorage.getItem("ds_token")) {
      return this.setState({ isOpenSignUpModal: true });
    } else {
      if (this.checkUserAndLoggedInAccountType()) {
        return this.setState({ checkout: true });
      }
      this.dispatch.logout();
      return this.setState({ isOpenSignUpModal: true });
    }
  };

  //===========================================================================
  // click handler for Add to Cart button
  //===========================================================================
  onAddToCart = async () => {
    if (!this.isPlanSelected()) {
      return toast.error(`Please select a plan!`);
    }
    const planId = this.state.selected;

    if (this.failedLocalStorageDsUserToken()) {
      console.log(
        "onAddToCart: Failed to access local storage. Invoking window.open()."
      );
      window.open(
        `${process.env.REACT_APP_URL}/programs/${this.props.match.params.slug}/program/${this.state.program.id}?plan=${planId}`
      );
      return;
    }

    this.setState({ checkoutType: "Cart" });

    const data = {
      partnerId: this.state.program.partnerId,
      productId: this.state.program.id,
      planId: planId,
      quantity: this.state.singleSessionQuantity[planId]
        ? this.state.singleSessionQuantity[planId]
        : 1,
      productTable: "partner_programs"
    };
    const addToCart = async () => {
      await this.dispatch.getCart({
        partnerSlug: this.props.match.params.slug
      });
      const ep = `${process.env.REACT_APP_API}/par/cart`;
      const res = await axios.put(ep, data);
      if (res.data.success) {
        toast.success("Added to cart!");
        sendAddToCartEvent({name: this.state.program.program_name});
        this.setState({ showCart: true });
        console.log("this.global.cart:", this.global.cart);
      } else {
        toast.error(res.data.message);
      }
    };

    if (localStorage.getItem("ds_token") && !this.global.dsUser) {
      await this.dispatch.validateToken();
      if (this.checkUserAndLoggedInAccountType()) {
        await addToCart();
        return;
      }
      this.dispatch.logout();
      return this.setState({ isOpenSignUpModal: true });
    }
    if (!localStorage.getItem("ds_token")) {
      return this.setState({ isOpenSignUpModal: true });
    } else {
      if (this.checkUserAndLoggedInAccountType()) {
        await addToCart();
        return;
      }
      this.dispatch.logout();
      return this.setState({ isOpenSignUpModal: true });
    }
  };
  //=================================================================================================

  render() {
    console.log("partnerSpecificProgram.jsx 288: this.state", this.state);
    console.log("partnerSpecificProgram.jsx 289: this.props", this.props);
    console.log("this.state.program", this.state.program);
    if (!this.state.program["main_lat"]) {
      return null;
    }

    if (this.props.match.params.slug !== this.state.orgSlug) {
      console.log("bad url");
      return null;
    }

    console.log(
      "failedLocalStorageDsUserToken",
      this.failedLocalStorageDsUserToken()
    );

    console.log(
      "partnerSpecificProgram.jsx 314: this.state.checkout",
      this.state.checkout
    );
    console.log(
      "partnerSpecificProgram.jsx 315: this.global.dsUser",
      this.global.dsUser
    );

    /* No Timezones needed for this display of a time range. */
    let timing = this.state.program.timing;

    if (
      this.state.program.program_category === "Single Sessions" &&
      this.state.program.enableCustomTimes
    ) {
      let timeRanges = [];
      for (const day in this.state.program.customTimes) {
        this.state.program.customTimes[day].map((e, index) => {
          if (index % 2 === 0) {
            timeRanges.push([
              parseFloat(e),
              parseFloat(this.state.program.customTimes[day][index + 1])
            ]);
            return e;
          } else {
            return e;
          }
        });
      }
      // Sort in decreasing order the length of the time ranges.
      timeRanges.sort((a, b) => b[1] - b[0] - (a[1] - a[0]));

      // For program single sessions with custom times, show the greatest time range.
      timing[0] = String(timeRanges[0][0]);
      timing[1] = String(timeRanges[0][1]);
    } else if (this.state.program.program_category === "Single Sessions") {
      let timeRanges = [];
      timing.map((e, index) => {
        if (index % 2 === 0) {
          timeRanges.push([parseFloat(e), parseFloat(timing[index + 1])]);
          return e;
        } else {
          return e;
        }
      });
      // Sort in decreasing order the length of the time ranges.
      timeRanges.sort(
        (a, b) =>
          parseFloat(b[1]) -
          parseFloat(b[0]) -
          (parseFloat(a[1]) - parseFloat(a[0]))
      );

      // For program single sessions, show the greatest time range.
      if (timeRanges[0][0] !== "-1" && timeRanges[0][1] !== "-1") {
        timing = timeRanges[0];
      }
    }
    let formattedTimeRange = "--";
    if (
      timing &&
      timing.length === 2 &&
      timing[0] !== "-1" &&
      timing[1] !== "-1"
    ) {
      let { startTime, endTime } = constructTimeRangeFromDecimalHours(
        parseFloat(timing[0]),
        parseFloat(timing[1]),
        null
      );
      formattedTimeRange = getFormattedTimeRange(
        startTime,
        endTime,
        /*separator=*/ " - ",
        /*hideTZ=*/ true
      );
    } else {
      console.error(
        "partnerSpecificProgram was not meant to handle timing.length > 2"
      );
      formattedTimeRange = "<Error>";
    }

    let formattedOpenDays = getGroupedWeekdayRange(
      this.state.program.days_of_week
    );

    const queryParams = this.props.location.search
      ? this.props.location.query ||
      this.props.history.location.query ||
      qs.parse(this.props.location.search.substr(1))
      : {};
    //below, we're taking extra precautions for invites - because some may plans may be active=false.
    const onlyShowOnePlanEvenIfInactive =
      queryParams.visible && queryParams.visible !== "false" && queryParams.plan;
    const urlPreSelectedPlan = testIsInteger(queryParams.plan)
      ? parseInt(queryParams.plan)
      : undefined;
    console.log(
      "partnerSpecificProgram.jsx : onlyShowOnePlanEvenIfInactive =",
      onlyShowOnePlanEvenIfInactive
    );
    console.log(
      "partnerSpecificProgram.jsx : urlPreSelectedPlan =",
      urlPreSelectedPlan
    );
    let noPlansAvailableMessage;

    //FS- I didn't write code below. I'm just de-duplicating code sections and making it easier to read.
    const adjustNumWeeks = this.state.program.isRecurring ? 0 : 1;
    const plansAllowedForEnrollment = this.state.program.plans.filter(e => {
      const element = typeof e === "string" ? JSON.parse(e) : e;
      let planEndDate = new Date(
        getLastDay(
          addWeeks(
            getFirstDay(
              new Date(this.state.program.program_start),
              this.state.program.days_of_week
            ),
            this.state.program.number_of_weeks - adjustNumWeeks
          ),
          this.state.program.days_of_week
        )
      );

      let hours = 0;
      let minutes = 0;

      let endHours = 0;
      let endMinutes = 0;

      if (this.state.program.enableCustomTimes) {
        const options = { weekday: "long" };
        const dayOfWeek = planEndDate.toLocaleString("en-US", options);
        const times = this.state.program.customTimes[dayOfWeek]
          .filter((value, index) => value !== -1 && index % 2 == 0)
          .sort(function (a, b) {
            return parseFloat(a) - parseFloat(b);
          });

        const time = times.length === 0 ? 0 : parseFloat(times[0]); // decimal value representing hours and minutes

        const endTimes = this.state.program.customTimes[dayOfWeek]
          .filter((value, index) => value !== -1 && index % 2 == 1)
          .sort(function (a, b) {
            return parseFloat(a) - parseFloat(b);
          });
        const endTime = endTimes.length === 0 ? 24 : parseFloat(endTimes[0]);

        hours = Math.floor(time); // get the integer part (hours) using Math.floor()
        minutes = Math.round((time - hours) * 60); // get the decimal part (minutes) and convert to minutes
        endHours = Math.floor(endTime);
        endMinutes = Math.floor((endTime - endHours) * 60);
        console.log(
          `${hours} hours and ${minutes} minutes | ${endHours} hours and ${endMinutes} minutes`
        ); // logs "5 hours and 15 minutes"

        if (hours && hours !== -1) {
          planEndDate = new Date(planEndDate.setHours(hours));
          if (minutes) {
            planEndDate = new Date(planEndDate.setMinutes(minutes));
          }
        }

        if (this.state.program.continuous_registration) {
          if (endHours && endHours !== -1) {
            planEndDate = new Date(planEndDate.setHours(endHours));
            if (endMinutes) {
              planEndDate = new Date(planEndDate.setMinutes(endMinutes));
            }
          }
        }
      } else {
        const decimalValue = this.state.program.timing
          .filter((value, index) => value !== "-1" && index % 2 === 0)
          .sort(function (a, b) {
            return parseFloat(a) - parseFloat(b);
          });

        const time =
          decimalValue.length === 0 ? 0 : parseFloat(decimalValue[0]); // decimal value representing hours and minutes

        const endDecimalValue = this.state.program.timing
          .filter((value, index) => value !== "-1" && index % 2 === 1)
          .sort(function (a, b) {
            return parseFloat(a) - parseFloat(b);
          });

        const endTime =
          endDecimalValue.length === 0 ? 24 : parseFloat(endDecimalValue[0]);

        hours = Math.floor(time); // get the integer part (hours) using Math.floor()
        minutes = Math.round((time - hours) * 60); // get the decimal part (minutes) and convert to minutes
        endHours = Math.floor(endTime);
        endMinutes = Math.round((endTime - endHours) * 60);
        console.log(
          `${hours} hours and ${minutes} minutes | ${endHours} hours and ${endMinutes} minutes`
        ); // logs "5 hours and 15 minutes"

        if (hours && hours !== -1) {
          planEndDate = new Date(planEndDate.setHours(hours));
          if (minutes) {
            planEndDate = new Date(planEndDate.setMinutes(minutes));
          }
        }

        if (this.state.program.continuous_registration) {
          if (endHours && endHours !== -1) {
            planEndDate = new Date(planEndDate.setHours(endHours));
            if (endMinutes) {
              planEndDate = new Date(planEndDate.setMinutes(endMinutes));
            }
          }
        }
      }

      const date = this.state.program.program_start.substring(0, 10);
      const dt = DateTime.fromISO(date, {
        zone: this.state.program.timezone
      }).toISO();
      const programStart = DateTime.fromISO(dt, {
        zone: this.state.program.timezone
      }).plus({ minutes: minutes, hour: hours });
      const rezonedProgramStart = programStart.setZone(
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
      const programEnd = DateTime.fromISO(dt, {
        zone: this.state.program.timezone
      }).plus({
        minutes: endMinutes,
        hour: endHours,
        weeks: this.state.program.number_of_weeks - adjustNumWeeks
      });
      const rezonedProgramEnd = programEnd.setZone(
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
      const currTime = DateTime.local();

      console.log(
        "DATE TIMING",
        dt,
        "|||",
        new Date(programStart),
        "|||",
        new Date(rezonedProgramStart),
        "|||",
        new Date(rezonedProgramEnd),
        "|||",
        new Date(currTime)
      );

      console.log("card", element);
      console.log("end date is", planEndDate);
      console.log("start date", new Date());
      console.log("Plan Ended", planEndDate < new Date());
      if (onlyShowOnePlanEvenIfInactive) {
        // this.props.location.query?.modifier === "I"
        return urlPreSelectedPlan === element.id;
      }
      const programEndDatePassed = this.state.program.continuous_registration
        ? new Date(rezonedProgramEnd) < new Date(currTime) &&
        !this.state.program.isRecurring
        : new Date(rezonedProgramStart) < new Date(currTime) &&
        !this.state.program.isRecurring;
      if (programEndDatePassed) {
        //if &visible=true, then this if-statement below is the final check. Don't care if active=false.
        console.log("end date passed");
        if (this.state.program.continuous_registration) {
          noPlansAvailableMessage = "- End date has passed";
        } else {
          noPlansAvailableMessage = "- Start date has passed";
        }
        return false;
      }

      return !element.archived && element.active !== false && element.isHidden !== true; //"active" can be undefined, null, or true! But not false!
    });
    console.log("plans allowed", plansAllowedForEnrollment);
    if (this.state.program?.published === false && (!this.props.location.query?.email || !this.props.location.query?.visible === "true")) {
      return <NoDataAvailable message="Program Unavailable" />
    }

    return this.state.checkout &&
      this.state.selected !== null &&
      this.global.dsUser ? (
      <Checkout
        form={this.state.program.formId}
        program={this.state.program}
        selected={this.state.selected}
        organization={this.state.program.organization_title}
        installments={this.state.installments}
        dropins={JSON.parse(this.state.program.plans.filter(plan => JSON.parse(plan).id === this.state.selected)[0]).type === "Drop-In"}
        close={_ => this.setState({ checkout: false })}
        type={"Program"}
        parentGuardians={this.global.parentGuardians}
        singleSessionQuantity={
          this.state.singleSessionQuantity[this.state.selected]
        }
        startDate={this.state.startDate}
        recurringCustomerSelectedDays={this.state.recurringCustomerSelectedDays}
        isSkipForm={this.state.isSkipForm}
        isWaiveDeposit={this.state.isWaiveDeposit}
        isStartSet={this.state.isStartSet}
        planWaitlist={this.state.planWaitlist}
        singleSessionSelectedDates={this.state.singleSessionSelectedDates}
      />
    ) : (
      <div className="containerall" style={{ overflowX: "hidden" }}>
        <ReactBnbGallery
          show={this.state.galleryShown}
          photos={this.state.gallery.map(
            e => `${process.env.REACT_APP_DS_FILES_S3 + "/" + e}`
          )}
          onClose={this.openGallery}
        />
        {this.state.showCart && (
          <CartModal
            partnerSlug={this.props.match.params.slug}
            animation={true}
            hideCart={() => {
              this.setState({ showCart: false });
            }}
            toggleAddToCart={() => {
              this.setState({ disableAddToCart: !this.state.disableAddToCart });
            }}
          />
        )}
        {this.state.signIn && (
          <SignIn
            onClose={_ => this.setState({ signIn: false, email: "" })}
            program={this.state.program}
            checkout={e => {
              console.log("checkout sign in modal", e);
              console.log("line 369");
              this.setState({ justSignedIn: e, signIn: false});
            }}
            openSignUpModal={_ => this.setState({ isOpenSignUpModal: true })}
            email={this.state.email}
          />
        )}

        {this.state.isOpenSignUpModal && (
          <SignUpNewModal
            onClose={_ => this.setState({ isOpenSignUpModal: false })}
            program={this.state.program}
            checkout={_ => {
              console.log("line 381");
              console.log("THE CHECKOUT TYPE", this.state.checkoutType);
              if (this.state.checkoutType === "Cart") {
                this.setState({ isOpenSignUpModal: false });
                this.onAddToCart();
              } else if (this.state.checkoutType === "Save") {
                this.setState({ isOpenSignUpModal: false });
                this.saveInterestedProgram();
              } else {
                this.setState({ checkout: true, isOpenSignUpModal: false });
              }
            }}
            openSignInModal={_ => this.setState({ signIn: true })}
            setEmail={enteredEmail => {
              this.setState({ email: enteredEmail });
            }}
          />
        )}
        {this.state.isOpenShareButtonModal && (
          <ShareButtonModal
            onClose={() => {
              this.setState({
                ...this.state,
                isOpenShareButtonModal: false
              })
              console.log("HERE", this.props.history.location.pathname)
              console.log((process.env.NODE_ENV === 'development' ? "http://localhost:3002" : process.env.REACT_APP_MODE === "staging" ? "https://staging.dreamschools.com" : "https://dreamschools.com")
                + this.props.history.location.pathname)
            }}
            url={(process.env.NODE_ENV === 'development' ? "http://localhost:3002" : process.env.REACT_APP_MODE === "staging" ? "https://staging.dreamschools.com" : "https://dreamschools.com")
              + this.props.history.location.pathname}
            // quote={"HELLO"}
            hashtag={"#DreamSchools"}
          // description={"DESCRIPTION STUFF"}
          />
        )}

        <div className="leftcontainerprogram">
          <div className="titledivision">
            <h1 className="maintitleprogram">
              {this.state.program.program_name}{" "}
              {this.state.isEnableWaitlist && (
                <span style={{ fontStyle: "italic" }}>
                  {"("}
                  <span style={{ color: "#e22b2b", fontStyle: "italic" }}>
                    On Waitlist
                  </span>
                  {")"}
                </span>
              )}
              {this.state.isBookingFull && (
                <span style={{ fontStyle: "italic" }}>
                  {"("}
                  <span style={{ color: "#e22b2b", fontStyle: "italic" }}>
                    FULL
                  </span>
                  {")"}
                </span>
              )}
              {/* {(this.state.alreadyEnrolled && !this.state.program.allow_same_enrollment && !this.state.program.addChild &&
                      this.state.program.program_category !== "Single Sessions" && !this.state.program.application_required) && (
                <span style={{ fontStyle: "italic" }}>
                  {"("}
                  <span style={{ color: "#e22b2b", fontStyle: "italic" }}>
                    Already Enrolled
                  </span>
                  {")"}
                </span>
              )} */}
            </h1>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <h4
                style={{
                  margin: `0px 0px 10px 0px`,
                  color: `#999`,
                  fontFamily: `Montserrat`,
                  fontWeight: `600`,
                  textDecoration: `underline`,
                  cursor: "pointer",
                  fontSize: "1.2rem"
                }}
                onClick={_ => {
                  this.props.location.pathname.includes("iframe")
                    ? this.props.history.push(
                      `/iframes/programs/${this.props.match.params.slug}`
                    )
                    : this.props.history.push(
                      `/programs/${this.props.match.params.slug}`
                    );
                }}
              >
                {this.state.program.organization_title}
              </h4>
              <div>
                <button
                  className="partnerbutton"
                  style={{ borderRadius: "10px", marginRight: "10px" }}
                  onClick={this.openGallery}
                >
                  Gallery
                </button>

                <button
                  className="partnerbutton"
                  style={{ borderRadius: "10px", marginRight: "10px" }}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      isOpenShareButtonModal: true,
                    })
                  }}
                >
                  Share
                </button>

                <button
                  className="partnerbutton"
                  style={{ borderRadius: "10px" }}
                  onClick={async () => {
                    if (!this.global.dsUser) {
                      this.setState({
                        isOpenSignUpModal: true,
                        checkoutType: "Save"
                      });
                    } else {
                      this.saveInterestedProgram();
                    }
                  }}
                >
                  {this.state.isInterested ? "Unsave" : "Save"}
                </button>
              </div>
            </div>
          </div>

          <div className="maincontainerdiv">
            <LazyLoadImage
              className={this.props.classes.cardImg}
              alt="program"
              src={`${process.env.REACT_APP_DS_FILES_S3}/${this.state.program.program_photo}`}
              wrapperClassName="imageprogram"
              effect="opacity"
            />
            <div className="widgetdata">
              <div
                className="box"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  marginBottom: "10px"
                }}
              >
                <img src={LiveView1} alt="icon" className="icon-img" />
                <p className="icon-text">
                  {formattedOpenDays}{" "}
                  {formattedTimeRange !== "<Error>"
                    ? `- ${formattedTimeRange}`
                    : ""}
                </p>
              </div>
              {!this.state.program.all_ages &&
                this.state.program.age_groupings.length === 0 ? null : (
                <div
                  className="box"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "10px",
                    marginBottom: "10px"
                  }}
                >
                  <img src={LiveView2} alt="icon" className="icon-img" />

                  <p className="icon-text">
                    {this.state.program.all_ages
                      ? "All Ages"
                      : this.state.program.age_groupings
                        .map(e => JSON.parse(e).name)
                        .join(", ")}
                  </p>
                </div>
              )}
              <div
                className="box"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  marginBottom: "10px"
                }}
              >
                <img src={LiveView3} alt="icon" className="icon-img" />

                <p className="icon-text">
                  {this.state.program.program_category}
                </p>
              </div>

              <div
                className="box"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  marginBottom: "10px"
                }}
              >
                <img src={LiveView5} alt="icon" className="icon-img" />
                {this.state.program.program_details === "Lessons / Sessions" ? (
                  <p className="icon-text">
                    Spots per Timeslot:{" "}
                    {this.state.program.total_spots
                      ? `${this.state.program.total_spots}`
                      : "# Spots Pending"}
                  </p>
                ) : (
                  <p className="icon-text">
                    Total Spots:{" "}
                    {this.state.program.total_spots
                      ? `${this.state.program.total_spots}`
                      : "# Spots Pending"}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="box-size">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <h3 className="sub-title">Description</h3>
              {this.state.showDescription ? (
                <i
                  className="fas fa-chevron-up"
                  style={{ cursor: "pointer" }}
                  onClick={_ =>
                    this.setState({
                      showDescription: !this.state.showDescription
                    })
                  }
                ></i>
              ) : (
                <i
                  className="fas fa-chevron-down"
                  style={{ cursor: "pointer" }}
                  onClick={_ =>
                    this.setState({
                      showDescription: !this.state.showDescription
                    })
                  }
                ></i>
              )}
            </div>
            {this.state.showDescription && (
              <p className="box-description-text">
                {this.state.program.description}
              </p>
            )}
          </div>
          {this.state.program.additional_notes && (
            <div className="box-size">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <h3 className="sub-title">Additional Details</h3>
                {this.state.showAdditionalDetails ? (
                  <i
                    className="fas fa-chevron-up"
                    style={{ cursor: "pointer" }}
                    onClick={_ =>
                      this.setState({
                        showAdditionalDetails: !this.state.showAdditionalDetails
                      })
                    }
                  ></i>
                ) : (
                  <i
                    className="fas fa-chevron-down"
                    style={{ cursor: "pointer" }}
                    onClick={_ =>
                      this.setState({
                        showAdditionalDetails: !this.state.showAdditionalDetails
                      })
                    }
                  ></i>
                )}
              </div>
              {this.state.showAdditionalDetails && (
                <p
                  // style={{
                  //   fontWeight: "400",
                  //   fontFamily: `"Open sans", sans-serif`,
                  //   whiteSpace: "pre-line",
                  //   marginBottom: "20px"
                  // }}
                  className="box-description-text"
                >
                  {this.state.program.additional_notes}
                </p>
              )}
            </div>
          )}

          {this.state.program.show_cancellation_policy && (
            <div className="box-size">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <h3 className="sub-title">Cancellation Policy</h3>
                {this.state.showCancellationPolicy ? (
                  <i
                    className="fas fa-chevron-up"
                    style={{ cursor: "pointer" }}
                    onClick={_ =>
                      this.setState({
                        showCancellationPolicy:
                          !this.state.showCancellationPolicy
                      })
                    }
                  ></i>
                ) : (
                  <i
                    className="fas fa-chevron-down"
                    style={{ cursor: "pointer" }}
                    onClick={_ =>
                      this.setState({
                        showCancellationPolicy:
                          !this.state.showCancellationPolicy
                      })
                    }
                  ></i>
                )}
              </div>
              {this.state.showCancellationPolicy && (
                <p className="box-description-text">
                  {this.state.program.cancellation_policy}
                </p>
              )}
            </div>
          )}

          {this.state.program.show_location && (
            <div className="box-size">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <h3 className="sub-title">Location</h3>
                {this.state.showLocation ? (
                  <i
                    className="fas fa-chevron-up"
                    style={{ cursor: "pointer" }}
                    onClick={_ =>
                      this.setState({ showLocation: !this.state.showLocation })
                    }
                  ></i>
                ) : (
                  <i
                    className="fas fa-chevron-down"
                    style={{ cursor: "pointer" }}
                    onClick={_ =>
                      this.setState({ showLocation: !this.state.showLocation })
                    }
                  ></i>
                )}
              </div>
              {this.state.showLocation && (
                <div className="map-size box-description-text">
                  <p>Address : {this.state.program.address1}</p>
                  {/* <div className="show-map"> */}
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyAVaN8qvELLoX3nYdqFC91GqbxXLcV-cig"
                    }}
                    defaultZoom={9}
                    defaultCenter={{
                      lat: parseFloat(this.state.program.main_lat),
                      lng: parseFloat(this.state.program.main_long)
                    }}
                    options={{ gestureHandling: "greedy" }}
                  >
                    {this.getLocation(this.state.program.other_locations)}
                  </GoogleMapReact>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="rightcontainerprogram">
          <h2 className="right-cont-name">Booking Details</h2>

          <div style={{ width: "80%", margin: "0 auto" }}>
            {/* ================================== Display No Plans Available message ==================================*/}
            {plansAllowedForEnrollment.length === 0 && (
              <div>
                <p>No plans available {noPlansAvailableMessage}</p>
              </div>
            )}
            {/* ================================== Display Price Cards for each plan ==================================*/}

            {plansAllowedForEnrollment
              .sort((prev, next) => {
                let newPrev = prev;
                let newNext = next;
                if (typeof prev === "string") {
                  newPrev = JSON.parse(prev);
                }
          
                if (typeof next === "string") {
                  newNext = JSON.parse(next);
                }
          
                return newPrev.order - newNext.order;
              })
              .map((e, i) => {
                const element = typeof e === "string" ? JSON.parse(e) : e;
                console.log("ELEMENT:", element);
                /*
                //DELETE THIS SECTION LATER. MOVED IT INTO "plansForEnrollment" init above!
                //===========
                const location = this.props.location || {};
                if ((location.query && location.query.plan && location.query.visible)
                    || (location.search && location.search.includes("visible=true"))
                ) {
                  element.active = (parseInt(element.id) === this.state.selected);
                }
                if (element.archived || element.active === false) {
                  return null;
                }
                */

                let planEnrollment = 0;
                this.state.customers.Enrollments.forEach(e => {
                  if (
                    e.status_stage === "Accepted_Paid" &&
                    element.id === e.plan_id
                  ) {
                    planEnrollment++;
                  }
                });
                if (!element.type)
                  return (
                    <div
                      className={
                        this.state.selected === parseInt(element.id)
                          ? this.props.classes.planSelected + " pricingcard"
                          : this.props.classes.plan + " pricingcard"
                      }
                      onClick={_ =>
                        this.setState({ selected: parseInt(element.id) })
                      }
                      key={i}
                    >
                      <div className="radio-btn-positioning-container">
                        <Radio
                          checked={this.state.selected === parseInt(element.id)}
                          value="d"
                          color="default"
                          name="radio-button-demo"
                          inputProps={{ "aria-label": "D" }}
                          size="large"
                          classes={{
                            root: this.props.classes.radio,
                            checked: this.props.classes.checkedRadio
                          }}
                        />
                      </div>
                      <div style={{ width: "90%" }}>
                        <p className="pricingcard-name">
                          Recurring ({element.billing_cycle})
                        </p>

                        <p className="pricingcard-days">
                          {/* eslint-disable-next-line eqeqeq*/}
                          {element.days_per_week}{" "}
                          {
                            /*currently a string but will be integer in future*/ element.days_per_week ==
                              "1"
                              ? "day"
                              : "days"
                          }{" "}
                          / week
                          {!element.isReplaceTimeEnabled ? (
                            <span style={{ fontStyle: "italic" }}>
                              {" ("}
                              {element.timing[0]
                                .replace(/\s/g, "")
                                .toLowerCase()}{" "}
                              -{" "}
                              {element.timing[1]
                                .replace(/\s/g, "")
                                .toLowerCase()}
                              )
                            </span>
                          ) : (
                            <span style={{ fontStyle: "italic" }}>
                              {" ("}
                              {element.description}
                              {")"}
                            </span>
                          )}
                        </p>
                      </div>
                      <div style={{ width: "72px" }}>
                        <p className="pricingcard-amount">
                          ${element.tuition_rate} / {element.billing_cycle}
                        </p>
                      </div>
                    </div>
                  );
                else if (element.type === "Semester") {
                  let totalAddOnFee = 0;
                  if (element.addOn) {
                    element.addOnItems.map(item => {
                      totalAddOnFee += parseFloat(item.value);
                    });
                  }
                  let amountTemp;
                  let totalSessionsTemp;
                  let sessionsPassedTemp;
                  if (element.isProrated) {
                    const { totalSessions, sessionsPassed, amount } = calculateProratedClasses(this.state.program, element.total_price);
                    amountTemp = amount;
                    totalSessionsTemp = totalSessions;
                    sessionsPassedTemp = sessionsPassed;
                    if (!Number.isInteger(amountTemp)) {
                      amountTemp = amountTemp.toFixed(2);
                    }
                  }
                  return (
                    <>
                      {(!onlyShowOnePlanEvenIfInactive || (onlyShowOnePlanEvenIfInactive && !this.props.location.query?.modifier)) &&
                        <div
                          className={
                            this.state.selected === parseInt(element.id) &&
                              !this.state.dropIns &&
                              !this.state.installments
                              ? this.props.classes.planSelected + " pricingcard"
                              : this.props.classes.plan + " pricingcard"
                          }
                          onClick={_ => {
                            this.setState({
                              selected: parseInt(element.id),
                              dropIns: false,
                              installments: false
                            });
                            if (
                              element.planCap &&
                              element.planCap !== "" &&
                              parseInt(element.planCap) <= planEnrollment
                            ) {
                              this.setState({ planWaitlist: true });
                            } else {
                              this.setState({ planWaitlist: false });
                            }
                          }}
                          key={i}
                        >
                          <div className="radio-btn-positioning-container">
                            <Radio
                              checked={
                                this.state.selected === parseInt(element.id) &&
                                !this.state.dropIns &&
                                !this.state.installments
                              }
                              value="d"
                              color="default"
                              name="radio-button-demo"
                              inputProps={{ "aria-label": "D" }}
                              size="large"
                              classes={{
                                root: this.props.classes.radio,
                                checked: this.props.classes.checkedRadio
                              }}
                            />
                          </div>
                          <div style={{ width: "90%" }}>
                            <p className="pricingcard-name">
                              {element.description &&
                                element.description !== null ? (
                                <span style={{ fontStyle: "italic" }}>
                                  {element.description}
                                </span>
                              ) : (
                                "Semester"
                              )}
                            </p>
                            {element.isProrated && sessionsPassedTemp != 0 && (
                              <p className="pricingcard-days">
                                Prorated (original price ${element.total_price}) 
                              </p>
                            )}
                            <p className="pricingcard-days">
                              {format(
                                getFirstDay(
                                  new Date(this.state.program.program_start),
                                  this.state.program.days_of_week
                                ),
                                "LL/dd/yy"
                              )}{" "}
                              -{" "}
                              {format(
                                getLastDay(
                                  addWeeks(
                                    getFirstDay(
                                      new Date(
                                        this.state.program.program_start
                                      ),
                                      this.state.program.days_of_week
                                    ),
                                    this.state.program.number_of_weeks - 1
                                  ),
                                  this.state.program.days_of_week
                                ),
                                "LL/dd/yy"
                              )}
                            </p>
                            <span
                              style={{ fontWeight: "normal", fontSize: "12px" }}
                            ></span>
                            <p className="pricingcard-days">
                              {element.planCap && element.planCap != null
                                ? parseInt(element.planCap) <= planEnrollment
                                  ? `Spots ${element.planCap} (FULL)`
                                  : `Spots ${element.planCap}`
                                : ""}
                            </p>
                          </div>

                          <div style={{ width: "60px" }}>
                            <p className="pricingcard-amount">
                              ${element.isProrated ? amountTemp: element.total_price}
                            </p>
                          </div>
                        </div>
                      }
                      {(element.installments && (!onlyShowOnePlanEvenIfInactive || (onlyShowOnePlanEvenIfInactive &&
                        (this.props.location.query?.modifier === "Installments" || this.props.location.query?.modifier === "I")))) && (
                          <div
                            className={
                              this.state.selected === parseInt(element.id) &&
                                this.state.installments
                                ? this.props.classes.planSelected + " pricingcard"
                                : this.props.classes.plan + " pricingcard"
                            }
                            onClick={_ =>
                              this.setState({
                                selected: parseInt(element.id),
                                dropIns: false,
                                installments: true
                              })
                            }
                            key={i}
                          >
                            <div className="radio-btn-positioning-container">
                              <Radio
                                checked={
                                  this.state.selected === parseInt(element.id) &&
                                  this.state.installments
                                }
                                value="d"
                                color="default"
                                name="radio-button-demo"
                                inputProps={{ "aria-label": "D" }}
                                size="large"
                                classes={{
                                  root: this.props.classes.radio,
                                  checked: this.props.classes.checkedRadio
                                }}
                              />
                            </div>
                            <div style={{ width: "90%" }}>
                              <p className="pricingcard-name">
                                {element.description &&
                                  element.description !== null ? (
                                  <span style={{ fontStyle: "italic" }}>
                                    {element.description}
                                  </span>
                                ) : (
                                  "Semester"
                                )} - Inst
                              </p>
                              <p className="pricingcard-days">
                                {format(
                                  new Date(this.state.program.program_start),
                                  "LL/dd/yy"
                                )}{" "}
                                -{" "}
                                {format(
                                  addWeeks(
                                    new Date(this.state.program.program_start),
                                    this.state.program.number_of_weeks
                                  ),
                                  "LL/dd/yy"
                                )}
                              </p>
                              <span
                                style={{ fontWeight: "normal", fontSize: "12px" }}
                              ></span>
                            </div>
                            <div style={{ width: "60px" }}>
                              <p className="pricingcard-amount">
                                $
                                {parseFloat(
                                  ((parseFloat(element.total_price) +
                                    parseFloat(totalAddOnFee)) /
                                    element.installments_breakdown) *
                                  (1 +
                                    parseInt(element.installments_interest) /
                                    100)
                                ).toFixed(0)}{" "}
                                / {element.installments_plan}
                              </p>
                            </div>
                          </div>
                        )}
                      {element.dropIns && (
                        <div
                          className={
                            this.state.selected === parseInt(element.id) &&
                              this.state.dropIns
                              ? this.props.classes.planSelected + " pricingcard"
                              : this.props.classes.plan + " pricingcard"
                          }
                          onClick={_ =>
                            this.setState({
                              selected: parseInt(element.id),
                              dropIns: true,
                              installments: false
                            })
                          }
                          key={i}
                        >
                          <div className="radio-btn-positioning-container">
                            <Radio
                              checked={
                                this.state.selected === parseInt(element.id) &&
                                this.state.dropIns
                              }
                              value="d"
                              color="default"
                              name="radio-button-demo"
                              inputProps={{ "aria-label": "D" }}
                              size="large"
                              classes={{
                                root: this.props.classes.radio,
                                checked: this.props.classes.checkedRadio
                              }}
                            />
                          </div>
                          <div style={{ width: "90%" }}>
                            <p className="pricingcard-name">
                              {element.description &&
                                element.description !== null ? (
                                <span style={{ fontStyle: "italic" }}>
                                  {element.description}
                                </span>
                              ) : (
                                "Semester"
                              )} - Drop-in
                            </p>
                            <p className="pricingcard-days">
                              {format(
                                new Date(this.state.program.program_start),
                                "LL/dd/yy"
                              )}{" "}
                              -{" "}
                              {format(
                                addWeeks(
                                  new Date(this.state.program.program_start),
                                  this.state.program.number_of_weeks
                                ),
                                "LL/dd/yy"
                              )}
                            </p>
                            <span
                              style={{ fontWeight: "normal", fontSize: "12px" }}
                            ></span>
                          </div>

                          <div style={{ width: "60px" }}>
                            <p className="pricingcard-amount">
                              $
                              {parseFloat(
                                (element.total_price / element.dayCount) *
                                (1 + parseInt(element.dropin_interest) / 100)
                              ).toFixed(0)}
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  );
                } else if (element.type === "Single Sessions") {
                  if (!this.state.singleSessionQuantity[i]) {
                    this.state.singleSessionQuantity.push(1);
                  }
                  return (
                    <div
                      className={
                        this.state.selected === parseInt(element.id)
                          ? this.props.classes.planSelected + " pricingcard"
                          : this.props.classes.plan + " pricingcard"
                      }
                      onClick={_ =>
                        this.setState({ selected: parseInt(element.id) })
                      }
                      key={i}
                    >
                      <div className="radio-btn-positioning-container">
                        <Radio
                          checked={this.state.selected === parseInt(element.id)}
                          // onChange={handleChange}
                          value="d"
                          color="default"
                          name="radio-button-demo"
                          inputProps={{ "aria-label": "D" }}
                          size="large"
                          classes={{
                            root: this.props.classes.radio,
                            checked: this.props.classes.checkedRadio
                          }}
                        />
                      </div>
                      <div style={{ width: "90%" }}>
                        <p className="pricingcard-name">
                          Single (
                          {element.duration_type === "Class"
                            ? "Classes"
                            : element.duration_type}
                          {element.duration ? ` ${element.duration}mins` : ""})
                          x {this.state.singleSessionQuantity[i]}
                        </p>

                        <p
                          className="pricingcard-days"
                          style={{ textAlign: "left" }}
                        >
                          {this.state.program.number_of_weeks !== 0 &&
                            this.state.program.number_of_weeks !== -1
                            ? `${format(
                              getFirstDay(
                                new Date(this.state.program.program_start),
                                this.state.program.days_of_week
                              ),
                              "LL/dd/yy"
                            )} -
                              ${format(
                              getLastDay(
                                addWeeks(
                                  getFirstDay(
                                    new Date(
                                      this.state.program.program_start
                                    ),
                                    this.state.program.days_of_week
                                  ),
                                  this.state.program.number_of_weeks - 1
                                ),
                                this.state.program.days_of_week
                              ),
                              "LL/dd/yy"
                            )}`
                            : ""}
                        </p>
                        <p
                          className="pricingcard-days"
                          style={{ textAlign: "left", fontStyle: "italic" }}
                        >
                          {""}
                          {element.description}
                          {""}
                        </p>
                      </div>
                      <div style={{ width: "72px" }}>
                        <p className="pricingcard-amount">
                          ${element.total_price}{" "}
                          {element.frequency === "minutes"
                            ? ""
                            : `/ ${element.frequency}`}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              color: "#36b9cc",
                              marginLeft: "22px"
                            }}
                          >
                            <i
                              className="fas fa-minus-circle"
                              style={{ fontSize: "13px" }}
                              onClick={_ => {
                                if (this.state.singleSessionQuantity[i] === 1)
                                  return;
                                this.state.singleSessionQuantity[i] -= 1;
                                this.setState(prevState => {
                                  let singleSessionQuantity = [
                                    ...prevState.singleSessionQuantity
                                  ];
                                  return { singleSessionQuantity };
                                });
                              }}
                            ></i>
                            <i
                              className="fas fa-plus-circle"
                              style={{ fontSize: "13px" }}
                              onClick={_ => {
                                if (
                                  this.state.singleSessionQuantity[i] ===
                                  parseInt(element.quantity)
                                )
                                  return;
                                this.state.singleSessionQuantity[i] += 1;
                                this.setState(prevState => {
                                  let singleSessionQuantity = [
                                    ...prevState.singleSessionQuantity
                                  ];
                                  return { singleSessionQuantity };
                                });
                              }}
                            ></i>
                          </div>
                        </p>
                      </div>
                    </div>
                  );
                } else if (element.type === "Drop-In") {
                  return (
                    <>
                      <div
                        className={
                          this.state.selected === parseInt(element.id) &&
                            !this.state.dropIns &&
                            !this.state.installments
                            ? this.props.classes.planSelected + " pricingcard"
                            : this.props.classes.plan + " pricingcard"
                        }
                        onClick={_ => {
                          this.setState({
                            selected: parseInt(element.id),
                            dropIns: false,
                            installments: false
                          });
                          if (
                            element.planCap &&
                            element.planCap !== "" &&
                            parseInt(element.planCap) <= planEnrollment
                          ) {
                            this.setState({ planWaitlist: true });
                          } else {
                            this.setState({ planWaitlist: false });
                          }
                        }}
                        key={i}
                      >
                        <div className="radio-btn-positioning-container">
                          <Radio
                            checked={
                              this.state.selected === parseInt(element.id)
                            }
                            // onChange={handleChange}
                            value="d"
                            color="default"
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "D" }}
                            size="large"
                            classes={{
                              root: this.props.classes.radio,
                              checked: this.props.classes.checkedRadio
                            }}
                          />
                        </div>
                        <div style={{ width: "90%" }}>
                          <p className="pricingcard-name">
                            {element.description || element.type}
                          </p>
                          <p className="pricingcard-days">Time: {element.startTime}-{element.endTime} </p>
                          <span
                            style={{ fontWeight: "normal", fontSize: "12px" }}
                          ></span>
                          <p className="pricingcard-days">
                            {element.planCap && element.planCap != null
                              ? parseInt(element.planCap) <= planEnrollment
                                ? `Spots ${element.planCap} (FULL)`
                                : `Spots ${element.planCap}`
                              : ""}
                          </p>
                        </div>

                        <div style={{ width: "60px" }}>
                          <p className="pricingcard-amount">
                            ${(parseFloat(element.total_price) * (element.premium ? 1 + parseFloat(element.premium) / 100 : 1)).toFixed(2)}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                } else if (element.type === "Free Trial") {
                  return (
                    <>
                      <div
                        className={
                          this.state.selected === parseInt(element.id) &&
                            !this.state.dropIns &&
                            !this.state.installments
                            ? this.props.classes.planSelected + " pricingcard"
                            : this.props.classes.plan + " pricingcard"
                        }
                        onClick={_ => {
                          this.setState({
                            selected: parseInt(element.id),
                            dropIns: false,
                            installments: false
                          });
                          if (
                            element.planCap &&
                            element.planCap !== "" &&
                            parseInt(element.planCap) <= planEnrollment
                          ) {
                            this.setState({ planWaitlist: true });
                          } else {
                            this.setState({ planWaitlist: false });
                          }
                        }}
                        key={i}
                      >
                        <div className="radio-btn-positioning-container">
                          <Radio
                            checked={
                              this.state.selected === parseInt(element.id)
                            }
                            // onChange={handleChange}
                            value="d"
                            color="default"
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "D" }}
                            size="large"
                            classes={{
                              root: this.props.classes.radio,
                              checked: this.props.classes.checkedRadio
                            }}
                          />
                        </div>
                        <div style={{ width: "90%" }}>
                          <p className="pricingcard-name">
                            {element.description}
                          </p>
                          <p className="pricingcard-days">Free Trial for {element.trialPeriod} {element.trialPeriod == 1 ? "class" : "classes"} </p>
                          { element.noChildrenAvailable === false && element.allChildrenAvailable === false && 
                            <p className="pricingcard-days"> Available for: { }
                              { element.availableChildren.map((child) => child.fullName).join(', ')}
                            </p>}
                          <span
                            style={{ fontWeight: "normal", fontSize: "12px" }}
                          ></span>
                          <p className="pricingcard-days">
                            {element.planCap && element.planCap != null
                              ? parseInt(element.planCap) <= planEnrollment
                                ? `Spots ${element.planCap} (FULL)`
                                : `Spots ${element.planCap}`
                              : ""}
                          </p>
                        </div>

                        <div style={{ width: "60px" }}>
                          <p className="pricingcard-amount">
                            ${element.total_price}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                } else if (element.type === "Free")
                  return (
                    <>
                      <div
                        className={
                          this.state.selected === parseInt(element.id) &&
                            !this.state.dropIns &&
                            !this.state.installments
                            ? this.props.classes.planSelected + " pricingcard"
                            : this.props.classes.plan + " pricingcard"
                        }
                        onClick={_ => {
                          this.setState({
                            selected: parseInt(element.id),
                            dropIns: false,
                            installments: false
                          });
                          if (
                            element.planCap &&
                            element.planCap !== "" &&
                            parseInt(element.planCap) <= planEnrollment
                          ) {
                            this.setState({ planWaitlist: true });
                          } else {
                            this.setState({ planWaitlist: false });
                          }
                        }}
                        key={i}
                      >
                        <div className="radio-btn-positioning-container">
                          <Radio
                            checked={
                              this.state.selected === parseInt(element.id)
                            }
                            // onChange={handleChange}
                            value="d"
                            color="default"
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "D" }}
                            size="large"
                            classes={{
                              root: this.props.classes.radio,
                              checked: this.props.classes.checkedRadio
                            }}
                          />
                        </div>
                        <div style={{ width: "90%" }}>
                          <p className="pricingcard-name">
                            {element.description}
                          </p>
                          <p className="pricingcard-days">{element.isBilledLater ? "Billed Later (Invoice to be sent)" : "Free"}</p>
                          <span
                            style={{ fontWeight: "normal", fontSize: "12px" }}
                          ></span>
                          <p className="pricingcard-days">
                            {element.planCap && element.planCap != null
                              ? parseInt(element.planCap) <= planEnrollment
                                ? `Spots ${element.planCap} (FULL)`
                                : `Spots ${element.planCap}`
                              : ""}
                          </p>
                        </div>

                        <div style={{ width: "60px" }}>
                          <p className="pricingcard-amount">
                            ${element.total_price}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                //this final "else" block prevents eslint warning: Expected to return a value at the end of arrow function.
                else {
                  throw new Error(
                    `Unrecognized plan.type="${element.type}" for programId=${this.props.match.params.id} and planId=${element.id}`
                  );
                }
              })}
            {console.log(plansAllowedForEnrollment)}
            {this.state.selected !== null &&
              !isNaN(this.state.selected) &&
              plansAllowedForEnrollment.length > 0 && (
                <SummarySubTotal
                  /* plans={this.state.program.plans} */
                  /* selected={this.state.selected} */
                  plans={plansAllowedForEnrollment}
                  program = {this.state.program}
                  selectedPlanId={
                    plansAllowedForEnrollment.length > 0
                      ? this.state.selected
                      : null
                  }
                  quantity={
                    this.state.singleSessionQuantity[this.state.selected]
                  }
                />
              )}
            {(this.state.program.isCartDisabled ||
              this.state.selectedPlan?.addOn) &&
              plansAllowedForEnrollment.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <button
                  style={{
                    width: `70%`,
                    padding: `10px`,
                    backgroundColor: `rgb(52, 63, 100)`,
                    color: `white`,
                    marginTop: "10px",
                    fontWeight: `bold`,
                    border: 0,
                    cursor:
                      this.state.selected === null ||
                        this.state.isBookingFull ||
                        (this.state.planWaitlist && !this.state.isProgramWaitlist)
                        ? "not-allowed"
                        : "pointer"
                  }}
                  disabled={
                    this.state.selected === null ||
                    this.state.isBookingFull ||
                    (this.state.planWaitlist && !this.state.isProgramWaitlist)
                  }
                  onClick={this.onEnrollNow}
                >
                  Enroll Here Now
                </button>
              </div>
            ) : plansAllowedForEnrollment.length > 0 &&
              // Check if plan is recurring or installment and if so, do not show the "Add to Cart" button
              (this.state.installments ||
                this.state.program.auto_withdraw ||
                this.state.program.program_details === "Recurring") ? (
              <>
                <button
                  style={{
                    width: `70%`,
                    padding: `10px`,
                    backgroundColor: `rgb(52, 63, 100)`,
                    color: `white`,
                    fontWeight: `bold`,
                    border: 0,
                    marginTop: "40px",
                    cursor:
                      this.state.selected === null ||
                        this.state.isBookingFull ||
                        (this.state.planWaitlist && !this.state.isProgramWaitlist)
                        ? "not-allowed"
                        : "pointer"
                  }}
                  disabled={
                    this.state.selected === null ||
                    this.state.isBookingFull ||
                    (this.state.planWaitlist && !this.state.isProgramWaitlist)
                  }
                  onClick={this.onEnrollNow}
                >
                  Enroll Here Now
                </button>
                <div>
                  <i className="bi bi-info-circle" /> Recurring programs must be
                  checked out individually.
                </div>
              </>
            ) : plansAllowedForEnrollment.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <button
                  style={{
                    width: `70%`,
                    padding: `10px`,
                    backgroundColor: `rgb(52, 63, 100)`,
                    color: `white`,
                    fontWeight: `bold`,
                    border: 0,
                    marginTop: "40px",
                    cursor:
                      this.state.disableAddToCart ||
                        this.state.selected === null ||
                        this.state.isBookingFull ||
                        (this.state.planWaitlist && !this.state.isProgramWaitlist) ||
                        (this.state.selectedPlan && Object.keys(this.global.cart).length > 0 && this.state.selectedPlan.noChildrenAvailable === false 
                        && this.state.selectedPlan.allChildrenAvailable === false
                        && this.state.selectedPlan.availableChildren.length <= this.global.cart[this.props.match.params.slug].items.filter((item) => item.planId === this.state.selected).length
                      )
                        ? "not-allowed"
                        : "pointer"
                  }}
                  disabled={
                    this.state.disableAddToCart ||
                    this.state.selected === null ||
                    this.state.isBookingFull ||
                    (this.state.planWaitlist && !this.state.isProgramWaitlist) ||
                    (this.state.selectedPlan && Object.keys(this.global.cart).length > 0 && this.state.selectedPlan.noChildrenAvailable === false 
                      && this.state.selectedPlan.allChildrenAvailable === false
                      && this.state.selectedPlan.availableChildren.length <= this.global.cart[this.props.match.params.slug].items.filter((item) => item.planId === this.state.selected).length
                    )
                  }
                  onClick={this.onAddToCart}
                >
                  Add to Cart
                </button>
                <span>-------- OR --------</span>
                <button
                  style={{
                    width: `70%`,
                    padding: `10px`,
                    backgroundColor: `rgb(211,52,49)`,
                    color: `white`,
                    marginTop: "10px",
                    fontWeight: `bold`,
                    border: 0,
                    cursor:
                      this.state.selected === null ||
                        this.state.isBookingFull ||
                        (this.state.planWaitlist && !this.state.isProgramWaitlist)
                        ? "not-allowed"
                        : "pointer"
                  }}
                  disabled={
                    this.state.selected === null ||
                    this.state.isBookingFull ||
                    (this.state.planWaitlist && !this.state.isProgramWaitlist)
                  }
                  onClick={this.onEnrollNow}
                >
                  Enroll Here Now
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(withRouter(SpecificProgram));
